import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { TranslocoLoaderData } from '@jsverse/transloco/lib/transloco.loader';
import { Environment } from '@rma/generic/util/environment';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly environment: Environment,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  public getTranslation(locale: string, data?: TranslocoLoaderData) {
    // replace the last occurance of - with _
    const localePath = locale.replace(/(\s*-)(?![\s\S]*-)/, '_');

    const { url, site } = this.environment.language;
    const assetsLangJson = data?.scope ? `${url}/scope/${localePath}.json` : `${url}/site/${site ?? 'public'}/${localePath}.json`;
    const showKeys = this.window.location.search.includes('doesthismakeyouhappyanton');
    return showKeys ? of({}) : this.httpClient.get<Translation>(assetsLangJson).pipe(catchError(() => of({})));
  }
}

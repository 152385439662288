import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StripNewLinePipe } from './strip-new-line.pipe';

@NgModule({
  declarations: [StripNewLinePipe],
  imports: [CommonModule],
  exports: [StripNewLinePipe],
  providers: [StripNewLinePipe],
})
export class StripNewLinePipeModule {}

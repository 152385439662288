import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'translateHtml',
  standalone: true,
})
export class TranslateHtmlPipe implements PipeTransform {
  public constructor(private readonly translocoService: TranslocoService) {}

  public transform(value: string): string {
    return this.translocoService.translate(value).replace(/\\n/g, '');
  }
}
